import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { RouteProps } from 'react-router-dom'
import { media } from 'src/media'

const Root = styled.div`
  background: #eff5fe;
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px 48px 0;

  ${media.tablet`
    padding: 60px 20px 0;
    min-height: 100vh;
  `}
`

const Twologoslink = styled.a`
  display: block;

  :hover {
    cursor: pointer;
  }
`

const IconWrap = styled.img`
  width: 156px;
  height: 90px;
  margin-bottom: 65px;

  ${media.mobile`
    width: 104px;
    height: 60px;
    margin-bottom: 50px;
  `}
`

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 78px;

  ${media.laptop`
    margin: 0 30px;
  `}

  ${media.tablet`
    margin: 0 10px;
  `}
`

const IconLaurel = styled.img`
  width: 112px;
  height: 306px;

  ${media.tablet`
    display: none;
  `}
`

const Title = styled.h1`
  font-family: var(--fonts-second);
  font-size: 52px;
  line-height: 55px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: 400;
  text-align: center;

  ${media.tablet`
    font-size: 40px;
    line-height: 45px;
  `}

  ${media.mobile`
    font-size: 32px;
    line-height: 38px;
  `}

  ${media.premobile`
    font-size: 30px;
    line-height: 35px;
  `}
`

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: var(--color-ground-1000);
  margin-bottom: 40px;
  text-align: center;

  ${media.tablet`
    margin-bottom: 100px;
    max-width: 300px;
  `}

  ${media.mobile`
    display: none;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 10px;

  ${media.mobile`
    margin: 0;
  `}
`

const Button = styled.a`
  padding: 15px 50px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50px;
  margin-bottom: 15px;
  color: var(--color-black);
  transition: 0.3s;

  :first-child {
    margin-right: 26px;

    ${media.tabletX`
      margin-right: 0;
    `}
  }

  ${media.tablet`
    width: 100%;
    justify-content: center;
  `}

  :hover {
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px #201f6412;
    box-shadow: 0px 12px 20px 0px #201f640a;
  }
`

const IconButton = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`

const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 22px 160px 30px;
  background: #eff5fe;

  ${media.laptop`
    padding: 22px 48px 30px;
  `}
`

const Footerlink = styled.a`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  opacity: 50%;
  color: var(--color-ground-1000);

  :hover {
    cursor: pointer;
  }
`

const FooterText = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  opacity: 50%;
  color: var(--color-ground-1000);

  ${media.tablet`
    display: none;
  `}
`

export const PageUninstall: React.FC<RouteProps> = observer(() => {
  return (
    <Root>
      <Twologoslink
        href="https://pallie.ai/partner/?&utm_source=Breathhh&utm_medium=Emblems"
        target="_blank"
      >
        <IconWrap
          width="156"
          height="90"
          src="/assets/emblems-group.svg"
          alt="Breathhh Pallie Logo"
        />
      </Twologoslink>
      <MainWrapper>
        <IconLaurel width="112" height="306" src="/assets/laurel-left.svg" alt="Laurel Logo" />
        <ContentWrapper>
          <Title>
            Meet the Top&#8209;Secret
            <br /> AI Companion 🤫
          </Title>
          <Subtitle>Your Messenger — Your Well-being: Hyper-Personal, Empathic, Proactive</Subtitle>
          <ButtonWrapper>
            <Button
              href="https://pallie.ai/partner/?&utm_source=Breathhh&utm_medium=iMessage"
              target="_blank"
            >
              <IconButton
                width="25"
                height="25"
                src="/assets/icon-imessage.svg"
                alt="iMessage Logo"
              />
              Start in iMessage
            </Button>
            <Button
              href="https://pallie.ai/partner/?&utm_source=Breathhh&utm_medium=Telegram"
              target="_blank"
            >
              <IconButton
                width="25"
                height="25"
                src="/assets/icon-telegram.svg"
                alt="Telegram Logo"
              />
              Start in Telegram
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
        <IconLaurel width="112" height="306" src="/assets/laurel-right.svg" alt="Laurel Logo" />
      </MainWrapper>
      <Footer>
        <Footerlink href="https://breathhh.app" target="_blank">
          Breathhh
        </Footerlink>
        <FooterText>Two Products, One Purpose</FooterText>
        <Footerlink href="https://pallie.ai/?&utm_source=Breathhh" target="_blank">
          Pallie
        </Footerlink>
      </Footer>
    </Root>
  )
})
